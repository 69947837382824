import { useEffect } from "react";
import Testimonials from "../Home/Testimonials";
import * as Icons from "../common/Icons";
import SecurityObjectives from "./SecurityObjectives";
import SecurityStrategy from "./SecurityStrategy";

const CyberSecurity = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="security-banner bg-center">
        <div className="w-full 2xl:container mx-auto lg:px-16 md:px-8 px-5 flex items-center h-full">
          <h1 className="font-montserrat lg:text-6xl md:text-5xl text-4xl font-semibold text-white">
            Cyber <br /> Security
          </h1>
        </div>
      </div>
      <div className="2xl:container mx-auto lg:px-16 md:px-8 px-5 py-16 xl:w-[85%] flex flex-col items-center">
        <p className="text-dark-green text-center font-raleway text-xl font-medium tracking-[6.6px]">
          Case Studies
        </p>
        <h2 className="font-raleway lg:text-4xl md:text-3xl text-2xl font-semibold text-white mt-5 text-center">
          Cyber Security
        </h2>
        <div className="mt-12 w-full">
          <h3 className="text-primary font-raleway md:text-4xl text-3xl font-semibold">
            Overview
          </h3>
          <p className="mt-5 text-white font-raleway md:text-xl text-lg">
            A mid-sized IT firm, specializing in offering cloud-based solutions
            for diverse industries, relies on our services to ensure the
            security of sensitive data, encompassing financial information and
            personal records for multiple clients. With a dedicated team
            overseeing system security and client data protection, the emphasis
            is on fortifying defenses in the ever-evolving digital landscape.
          </p>
        </div>
      </div>
      <SecurityObjectives />
      <SecurityStrategy />
      <div className="it-service my-10">
        <div className="2xl:container mx-auto px-10 h-full flex items-center justify-center">
          <h3 className="text-white font-raleway lg:text-4xl sm:text-2xl text-xl font-semibold text-center">
            We run all kinds of{" "}
            <span className="text-primary">IT services</span>{" "}
            that vows your success
          </h3>
        </div>
      </div>
      <Testimonials />
    </>
  );
};

export default CyberSecurity;
