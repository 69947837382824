import DetailsFooter from "../components/DetailsFooter";
import DetailsHeader from "../components/DetailsHeader";
import WebDevelopment from "../components/WebDevelopment";

const DevelopmentPage = () => {
  return (
    <>
      <DetailsHeader />
      <WebDevelopment/>
      <DetailsFooter />
    </>
  );
};

export default DevelopmentPage;
