import { useState } from "react";
import * as Icons from "../common/Icons";

const DiscoverCompany = () => {
  const [showStep, setShowStep] = useState<number>(2);
  return (
    <>
      <div id="about-us" className="2xl:container mx-auto lg:px-16 md:px-8 px-5 py-16 lg:w-[85%] w-full">
        <p className="text-primary text-center font-raleway md:text-xl text-base font-medium tracking-[6.6px]">
          Discover Our Company
        </p>
        <h2 className="font-raleway md:text-4xl sm:text-3xl text-2xl font-semibold text-white mt-5 text-center">
          We’ve been thriving for <span className="text-primary">4 years</span>
        </h2>
        <div className="grid md:grid-cols-4 gap-5 mt-10">
          <button
            onClick={() => setShowStep(1)}
            className={`${showStep === 1 ? "step-btn-active" : "step-btn"}`}
          >
            Who we are
          </button>
          {/* Step 1 */}
          <div className={`${showStep === 1 ? "block md:hidden" : "hidden"}`}>
            <h3 className="text-white font-raleway lg:text-3xl text-2xl mt-8 text-center">
              Who We Are
            </h3>
            <div className={`mt-14 md:grid md:grid-cols-4 grid-cols-1 gap-5`}>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">
                  <img src="/svgs/forward-thinking.svg" alt="" />
                </div>
                <h4 className="company-desc">Forward-thinking IT company</h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">
                  <img src="/svgs/innovation-icon.svg" alt="" />
                </div>
                <h4 className="company-desc">
                  Known for innovation and expertise
                </h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">
                  <img src="/svgs/driven-tech-icon.svg" alt="" />
                </div>
                <h4 className="company-desc">
                  Driven by tech, committed to excellence
                </h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">
                  <img src="/svgs/digital-transformation.svg" alt="" />
                </div>
                <h4 className="company-desc">
                  Embarking on digital transformation's crease
                </h4>
              </div>
            </div>
          </div>
          <button
            onClick={() => setShowStep(2)}
            className={`${showStep === 2 ? "step-btn-active" : "step-btn"}`}
          >
            Our Story
          </button>
          {/* Step 2 */}
          <div className={`${showStep === 2 ? "block md:hidden" : "hidden"}`}>
            <h3 className="text-white font-raleway lg:text-3xl text-2xl mt-8 text-center">
              The Story Behind Data Crypt
            </h3>
            <div className={`mt-14 grid md:grid-cols-4 grid-cols-1 gap-5`}>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">
                  <Icons.BulbIcon />
                </div>
                <h4 className="company-desc">
                  Better Understanding Of Customers to Predict their Behaviour
                </h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">
                  <Icons.ReportingIcon />
                </div>
                <h4 className="company-desc">
                  Reporting, Visualization And Automation to Gain Real-time
                  Insights
                </h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">
                  <Icons.ExperienceIcon />
                </div>
                <h4 className="company-desc">
                  Optimize Experience / Product / Service to Retain And Acquire
                  New Customers
                </h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">
                  <Icons.EfficiencyIcon />
                </div>
                <h4 className="company-desc">
                  Increase Efficiency to Decrease Costs And Increase Revenue
                </h4>
              </div>
            </div>
          </div>
          <button
            onClick={() => setShowStep(3)}
            className={`${showStep === 3 ? "step-btn-active" : "step-btn"}`}
          >
            Milestone
          </button>
          {/* Step 3 */}
          <div className={`${showStep === 3 ? "block md:hidden" : "hidden"}`}>
            <h3 className="text-white font-raleway lg:text-3xl text-2xl mt-8 text-center">
              Key accomplishments along the journey
            </h3>
            <div className={`mt-14 grid md:grid-cols-4 grid-cols-1 gap-5`}>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">2020</div>
                <h4 className="company-desc">
                  Expanded client base, increased revenues, increased size of
                  team and international footprint
                </h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">2021</div>
                <h4 className="company-desc">
                  Enhanced internal capacity, streamlined processes and adopted
                  cutting edge data warehousing techniques
                </h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">2022</div>
                <h4 className="company-desc">
                  Managed 150,000 Petabytes of data and processed 40 Million rows
                  daily
                </h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">2023</div>
                <h4 className="company-desc">
                  Positioned for exponential growth and innovation
                </h4>
              </div>
            </div>
          </div>
          <button
            onClick={() => setShowStep(4)}
            className={`${showStep === 4 ? "step-btn-active" : "step-btn"}`}
          >
            Methodology
          </button>
          {/* Step 4 */}
          <div className={`${showStep === 4 ? "block md:hidden" : "hidden"}`}>
            <h3 className="text-white font-raleway lg:text-3xl text-2xl mt-8 text-center">
              Key accomplishments along the journey
            </h3>
            <div className={`mt-14 grid md:grid-cols-4 grid-cols-1 gap-5`}>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">
                <img src="svgs/single-source.svg" alt="" />
                </div>
                <h4 className="company-desc">SINGLE SOURCE OF TRUTH</h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">
                <img src="svgs/to-the-point.svg" alt="" />
                </div>
                <h4 className="company-desc">TO THE POINT</h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                {/* <div className="company-icon">
                  <Icons.ExperienceIcon />
                </div> */}
                <img src="svgs/privacy-icon.svg" alt="" className="w-24 h-24" />
                <h4 className="company-desc">PRIVACY</h4>
              </div>
              <div className="flex flex-col items-center md:mb-0 mb-5">
                <div className="company-icon">
                <img src="svgs/storage-icon.svg" alt="" />
                </div>
                <h4 className="company-desc">STORAGE</h4>
              </div>
            </div>
          </div>
        </div>
        {/* Step 1 */}
        <div className={`${showStep === 1 ? "md:block hidden" : "hidden"}`}>
          <h3 className="text-white font-raleway lg:text-3xl text-2xl mt-8 text-center">
            Who We Are
          </h3>
          <div className={`mt-14 grid grid-cols-4 gap-5`}>
            <div className="flex flex-col items-center">
              <div className="company-icon">
                <img src="/svgs/forward-thinking.svg" alt="" />
              </div>
              <h4 className="company-desc">Forward-thinking IT company</h4>
            </div>
            <div className="flex flex-col items-center">
              <div className="company-icon">
                <img src="/svgs/innovation-icon.svg" alt="" />
              </div>
              <h4 className="company-desc">
                Known for innovation and expertise
              </h4>
            </div>
            <div className="flex flex-col items-center">
              <div className="company-icon">
                <img src="/svgs/driven-tech-icon.svg" alt="" />
              </div>
              <h4 className="company-desc">
                Driven by tech, committed to excellence
              </h4>
            </div>
            <div className="flex flex-col items-center">
              <div className="company-icon">
                <img src="/svgs/digital-transformation.svg" alt="" />
              </div>
              <h4 className="company-desc">
                Embarking on digital transformation's crease
              </h4>
            </div>
          </div>
        </div>
        {/* Step 2 */}
        <div className={`${showStep === 2 ? "md:block hidden" : "hidden"}`}>
          <h3 className="text-white font-raleway lg:text-3xl text-2xl mt-8 text-center">
            The Story Behind Data Crypt
          </h3>
          <div className={`mt-14 grid grid-cols-4 gap-5`}>
            <div className="flex flex-col items-center">
              <div className="company-icon">
                <Icons.BulbIcon />
              </div>
              <h4 className="company-desc">
                Better Understanding Of Customers to Predict their Behaviour
              </h4>
            </div>
            <div className="flex flex-col items-center">
              <div className="company-icon">
                <Icons.ReportingIcon />
              </div>
              <h4 className="company-desc">
                Reporting, Visualization And Automation to Gain Real-time
                Insights
              </h4>
            </div>
            <div className="flex flex-col items-center">
              <div className="company-icon">
                <Icons.ExperienceIcon />
              </div>
              <h4 className="company-desc">
                Optimize Experience / Product / Service to Retain And Acquire New
                Customers
              </h4>
            </div>
            <div className="flex flex-col items-center">
              <div className="company-icon">
                <Icons.EfficiencyIcon />
              </div>
              <h4 className="company-desc">
                Increase Efficiency to Decrease Costs And Increase Revenue
              </h4>
            </div>
          </div>
        </div>
        {/* Step 3 */}
        <div className={`${showStep === 3 ? "md:block hidden" : "hidden"}`}>
          <h3 className="text-white font-raleway lg:text-3xl text-2xl mt-8 text-center">
            Key accomplishments along the journey
          </h3>
          <div className={`mt-14 grid grid-cols-4 gap-5`}>
            <div className="flex flex-col items-center">
              <div className="company-icon">2020</div>
              <h4 className="company-desc">
                Expanded client base, increased revenues, increased size of
                team and international footprint
              </h4>
            </div>
            <div className="flex flex-col items-center">
              <div className="company-icon">2021</div>
              <h4 className="company-desc">
                Enhanced internal capacity, streamlined processes and adopted
                cutting edge data warehousing techniques
              </h4>
            </div>
            <div className="flex flex-col items-center">
              <div className="company-icon">2022</div>
              <h4 className="company-desc">
                Managed 150,000 Petabytes of data and processed 40 Million rows
                daily
              </h4>
            </div>
            <div className="flex flex-col items-center">
              <div className="company-icon">2023</div>
              <h4 className="company-desc">
                Positioned for exponential growth and innovation
              </h4>
            </div>
          </div>
        </div>
        {/* Step 4 */}
        <div className={`${showStep === 4 ? "md:block hidden" : "hidden"}`}>
          <h3 className="text-white font-raleway lg:text-3xl text-2xl mt-8 text-center">
            Key accomplishments along the journey
          </h3>
          <div className={`mt-14 grid grid-cols-4 gap-5`}>
            <div className="flex flex-col items-center">
              <div className="company-icon">
                <img src="svgs/single-source.svg" alt="" />
              </div>
              <h4 className="company-desc">Single source of truth</h4>
            </div>
            <div className="flex flex-col items-center">
              <div className="company-icon">
                <img src="svgs/to-the-point.svg" alt="" />
              </div>
              <h4 className="company-desc">To the point</h4>
            </div>
            <div className="flex flex-col items-center">
              {/* <div className="company-icon"> */}
              <img src="svgs/privacy-icon.svg" alt="" className="w-24 h-24" />
              {/* </div> */}
              <h4 className="company-desc">Privacy</h4>
            </div>
            <div className="flex flex-col items-center">
              <div className="company-icon">
                <img src="svgs/storage-icon.svg" alt="" />
              </div>
              <h4 className="company-desc">Storage</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverCompany;
