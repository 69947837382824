import * as Icons from "../common/Icons";
import { Link } from "react-router-dom";

const UpperNav = () => {
  return (
    <div className="w-full py-5 upper-nav md:block hidden">
      <div className="flex items-center md:justify-between justify-center 2xl:container mx-auto lg:px-16 px-5">
        <Link to="/">
          <img src="/images/logo.png" alt="" className="lg:w-60 w-40" />
        </Link>
        <div className="md:flex hidden lg;gap-16 gap-5 items-center">
          {/* <div className="flex items-center gap-4">
            <Icons.ClockIcon />
            <div>
              <h3 className="2xl:text-2xl xl:text-xl text-base font-raleway font-bold text-white">
                9:00 AM - 6:00 PM
              </h3>
              <h3 className="2xl:text-2xl xl:text-xl text-base font-raleway font-normal text-white">
                Monday to Saturday
              </h3>
            </div>
          </div> */}
          <div className="flex items-center gap-4">
            <Icons.PhoneIcon />
            <div className="flex flex-col">
              <a
                href="tel:+971 50 246 4747"
                className="2xl:text-2xl xl:text-xl text-base font-raleway font-bold text-white"
              >
                +971 50 246 4747
              </a>
              <a
                href="mailto:farhanzeb@datacrypt.ae"
                className="2xl:text-2xl xl:text-xl text-base font-raleway font-normal text-white"
              >
                farhanzeb@datacrypt.ae
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpperNav;
