import { useState } from "react";
import * as Icons from "../common/Icons";

const DataStrategy = () => {
  const [showKpi, setShowKpi] = useState<boolean>(true);
  const [showDataProtocol, setShowDataProtocol] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
  const [showSecurity, setShowSecurity] = useState<boolean>(false);

  const handleKpi = () => {
    setShowKpi(!showKpi);
    setShowDataProtocol(false);
    setShowPrivacy(false);
    setShowSecurity(false);
  };

  const handleDataProtocol = () => {
    setShowDataProtocol(!showDataProtocol);
    setShowKpi(false);
    setShowPrivacy(false);
    setShowSecurity(false);
  };

  const handlePrivacy = () => {
    setShowPrivacy(!showPrivacy);
    setShowKpi(false);
    setShowDataProtocol(false);
    setShowSecurity(false);
  };

  const handleSecurity = () => {
    setShowSecurity(!showSecurity);
    setShowKpi(false);
    setShowDataProtocol(false);
    setShowPrivacy(false);
  };

  return (
    <>
      <div className="py-14 2xl:container mx-auto sm:px-16 px-5 xl:w-[85%]">
        <h3 className="text-primary font-raleway lg:text-4xl text-3xl font-semibold text-center">
          Strategies Implemented:
        </h3>
        {/* Desktop view */}
        <div className="md:block hidden">
          <div className="mt-8 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              KPI-driven Analysis:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Implement a structured approach to track KPIs aligned with
              business goals. Utilize advanced analytics tools to derive
              insights and make informed decisions for improving customer
              experience and overall business performance.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Data Cleansing Protocols:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Develop and implement automated data validation and cleansing
              processes to enhance data quality. This involves regular checks,
              standardized formatting, and error correction mechanisms to ensure
              accuracy.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Privacy Compliance Measures:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Establish strict protocols and systems to comply with privacy
              regulations. This includes obtaining explicit consent, anonymizing
              data where needed, and creating a framework for data access and
              deletion as per regulations.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Security Infrastructure:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Invest in robust cybersecurity measures such as encryption, access
              controls, and regular security audits. Ensure data is protected
              from breaches or unauthorized access.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
        </div>

        {/* Mobile view */}
        <div className="md:hidden block">
          <div
            onClick={handleKpi}
            className={`mt-8 w-full ${
              showKpi ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              KPI-driven Analysis:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showKpi ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Implement a structured approach to track KPIs aligned with
              business goals. Utilize advanced analytics tools to derive
              insights and make informed decisions for improving customer
              experience and overall business performance.
            </p>
            <div
              className={`absolute ${
                showKpi ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleDataProtocol}
            className={`mt-8 w-full ${
              showDataProtocol ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Data Cleansing Protocols:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showDataProtocol ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Develop and implement automated data validation and cleansing
              processes to enhance data quality. This involves regular checks,
              standardized formatting, and error correction mechanisms to ensure
              accuracy.
            </p>
            <div
              className={`absolute ${
                showDataProtocol ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handlePrivacy}
            className={`mt-8 w-full ${
              showPrivacy ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Privacy Compliance Measures:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showPrivacy ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Establish strict protocols and systems to comply with privacy
              regulations. This includes obtaining explicit consent, anonymizing
              data where needed, and creating a framework for data access and
              deletion as per regulations.
            </p>
            <div
              className={`absolute ${
                showPrivacy ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleSecurity}
            className={`mt-8 w-full ${
              showSecurity ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Security Infrastructure:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showSecurity ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Invest in robust cybersecurity measures such as encryption, access
              controls, and regular security audits. Ensure data is protected
              from breaches or unauthorized access.
            </p>
            <div
              className={`absolute ${
                showSecurity ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
        </div>
        <h3 className="text-primary font-raleway text-4xl font-semibold text-center mt-20">
          Results:
        </h3>
        <div className="mt-8 flex lg:flex-nowrap flex-wrap items-center justify-center xl:gap-20 sm:gap-10 gap-5">
          <div className="result-box h-60 sm:w-80 w-full flex flex-col items-center justify-center px-5">
            <h3 className="text-primary text-4xl font-semibold font-raleway">
              13%
            </h3>
            <p className="font-raleway text-2xl font-semibold text-white mt-3 text-center">
              Increased customer satisfaction
            </p>
          </div>
          <div className="result-box h-60 sm:w-80 w-full flex flex-col items-center justify-center px-5">
            <h3 className="text-primary text-4xl font-semibold font-raleway">
              10%
            </h3>
            <p className="font-raleway text-2xl font-semibold text-white mt-3 text-center">
              Average revenue growth
            </p>
          </div>
          <div className="result-box h-60 sm:w-80 w-full flex flex-col items-center justify-center px-5">
            <h3 className="text-primary text-4xl font-semibold font-raleway">
              20%
            </h3>
            <p className="font-raleway text-2xl font-semibold text-white mt-3 text-center">
              Reduced customer churn
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataStrategy;
