import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="transformation-banner bg-center">
        <div className="w-full 2xl:container mx-auto lg:px-16 md:px-8 px-5 flex items-center h-full">
          <h1 className="font-montserrat lg:text-6xl md:text-5xl text-4xl font-semibold text-white">
            Privacy Policy
          </h1>
        </div>
      </div>
      <div className="2xl:container mx-auto lg:px-16 md:px-8 px-5 py-16 xl:w-[85%] flex flex-col items-center">
        <h2 className="font-raleway lg:text-4xl md:text-3xl text-2xl font-semibold text-white mt-5 text-center">
          Privacy Policy
        </h2>
        <div className="w-full">
          <p className="mt-5 text-white font-raleway md:text-xl text-lg">
            Ensuring the confidentiality and security of your personal data is a
            core principle for us. We place a high value on your privacy and aim
            to maintain the trust you've vested in us. Our privacy policy serves
            as a detailed roadmap, meticulously outlining how we gather,
            process, manage, and protect your personal information when you
            access and use our services. It encompasses various aspects,
            delineating not just the collection but also the purpose and methods
            of using your data, ensuring transparency in how we handle and
            safeguard your information. By adhering to stringent privacy
            standards and regulatory requirements, we aim to provide you with
            clarity and confidence in entrusting us with your data when
            utilizing our services.
            <br />
            <br />
            <strong className="text-primary">
              1. Information Collection and Use:
            </strong>{" "}
            We collect personal information for the purpose of providing and
            improving our services. This information may include your name,
            email address, contact information, etc. <br /> <br />
            <strong className="text-primary">2. Data Security:</strong> We are committed to ensuring that your information is secure. We have implemented appropriate technical and organizational measures to safeguard and secure the information we collect. <br /> <br />
            <strong className="text-primary">
              3. Third-Party Links:
            </strong>{" "}
            Our website may contain links to third-party websites or services that are not operated by us. We have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies. <br /> <br />
            <strong className="text-primary">
              4. Changes to This Privacy Policy:
            </strong>{" "}
            We reserve the right to update or change our privacy policy at any time. You are advised to review this privacy policy periodically for any changes. <br /> <br />
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
