import DetailsHeader from "../components/DetailsHeader";
import DataAnalytics from "../components/DataAnalytics";
import DetailsFooter from "../components/DetailsFooter";

const DataAnalyticsPage = () => {
  return (
    <>
      <DetailsHeader />
      <DataAnalytics />
      <DetailsFooter />
    </>
  );
};

export default DataAnalyticsPage;
