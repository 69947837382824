import CyberSecurity from "../components/CyberSecurity";
import DetailsFooter from "../components/DetailsFooter";
import DetailsHeader from "../components/DetailsHeader";

const SecurityPage = () => {
  return (
    <>
      <DetailsHeader />
      <CyberSecurity/>
      <DetailsFooter />
    </>
  );
};

export default SecurityPage;
