import { useState } from "react";
import * as Icons from "../common/Icons";

const Strategy = () => {
  const [showVision, setShowVision] = useState<boolean>(true);
  const [showLeadership, setShowLeadership] = useState<boolean>(false);
  const [showTechnology, setShowTechnology] = useState<boolean>(false);
  const [showData, setShowData] = useState<boolean>(false);

  const handleVision = () => {
    setShowVision(!showVision);
    setShowLeadership(false);
    setShowTechnology(false);
    setShowData(false);
  };

  const handleLeadership = () => {
    setShowLeadership(!showLeadership);
    setShowVision(false);
    setShowTechnology(false);
    setShowData(false);
  };

  const handleTechnology = () => {
    setShowTechnology(!showTechnology);
    setShowVision(false);
    setShowLeadership(false);
    setShowData(false);
  };

  const handleData = () => {
    setShowData(!showData);
    setShowVision(false);
    setShowLeadership(false);
    setShowTechnology(false);
  };

  return (
    <>
      <div className="py-14 2xl:container mx-auto sm:px-16 px-5 xl:w-[85%]">
        <h3 className="text-primary font-raleway lg:text-4xl text-3xl font-semibold text-center">
          Strategies Implemented:
        </h3>
        {/* Desktop view */}
        <div className="md:block hidden">
          <div className="mt-8 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Vision and Strategy:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Clear goals and a well-defined strategy aligned with the company's
              vision are crucial. Understanding why digital transformation is
              necessary and setting measurable objectives is the foundation.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Leadership Support:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Strong leadership buy-in and support are vital. Leaders need to
              drive and advocate for change throughout the organization.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Technology Integration:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Implementing the right technology is crucial. This includes
              choosing scalable and adaptable systems, often involving
              cloud-based solutions, AI, IoT, and data analytics.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Data Utilization:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Leveraging data is a cornerstone of digital transformation.
              Analyzing data for insights, decision-making, and improving
              processes is critical.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
        </div>

        {/* Mobile view */}
        <div className="md:hidden block">
          <div
            onClick={handleVision}
            className={`mt-8 w-full ${
              showVision ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Vision and Strategy:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showVision ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Clear goals and a well-defined strategy aligned with the company's
              vision are crucial. Understanding why digital transformation is
              necessary and setting measurable objectives is the foundation.
            </p>
            <div
              className={`absolute ${
                showVision ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleLeadership}
            className={`mt-8 w-full ${
              showLeadership ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Leadership Support:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showLeadership ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Strong leadership buy-in and support are vital. Leaders need to
              drive and advocate for change throughout the organization.
            </p>
            <div
              className={`absolute ${
                showLeadership ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleTechnology}
            className={`mt-8 w-full ${
              showTechnology ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Technology Integration:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showTechnology ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Implementing the right technology is crucial. This includes
              choosing scalable and adaptable systems, often involving
              cloud-based solutions, AI, IoT, and data analytics.
            </p>
            <div
              className={`absolute ${
                showTechnology ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleData}
            className={`mt-8 w-full ${
              showData ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Data Utilization:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showData ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Leveraging data is a cornerstone of digital transformation.
              Analyzing data for insights, decision-making, and improving
              processes is critical.
            </p>
            <div
              className={`absolute ${
                showData ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
        </div>
        <h3 className="text-primary font-raleway text-4xl font-semibold text-center mt-20">
          Results:
        </h3>
        <div className="mt-8 flex sm:flex-row flex-col items-center justify-center md:gap-20 sm:gap-10 gap-5">
          <div className="result-box h-60 sm:w-80 w-full flex flex-col items-center justify-center px-5">
            <h3 className="text-primary text-4xl font-semibold font-raleway">
              30%
            </h3>
            <p className="font-raleway text-2xl font-semibold text-white mt-3 text-center">
              Decrease in fraudulent activities due to proactive identification
            </p>
          </div>
          <div className="result-box h-60 sm:w-80 w-full flex flex-col items-center justify-center px-5">
            <h3 className="text-primary text-4xl font-semibold font-raleway">
              25%
            </h3>
            <p className="font-raleway text-2xl font-semibold text-white mt-3 text-center">
              Increase in positive customer feedback and loyalty
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Strategy;
