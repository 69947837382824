import * as Icons from "../common/Icons";
import { Link } from "react-router-dom";
import { useState, ChangeEvent, FormEvent, useEffect } from "react";
import emailjs from "emailjs-com";

const DetailsFooter = () => {
  const [email, setEmail] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const validateEmailFormat = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = (): boolean => {
    // console.log('Email:', email);

    if (email.trim() === "" || !validateEmailFormat(email)) {
      // alert('Please enter a valid email address.');
      setErrorMessage("Please enter a valid email address.");
      return false;
    }

    return true;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form && validateForm()) {
      console.log("Form is valid");
      emailjs
        .sendForm(
          "service_akyfdkn",
          "template_afjbekz",
          form,
          "SQPDB41KRaajJ43Zt"
        )
        .then((response) => {
          // console.log('Email sent successfully:', response);
          // Additional actions after successful email submission
          setEmail("");
          setSuccessMessage("Submitted successfully!");
        })
        .catch((error) => {
          // console.error('Error sending email:', error);
          setSuccessMessage("Error sending email. Please try again.");
          // Handle error or provide user feedback
        });
      // console.log('Form submitted:', { email });
    } else {
      // console.log('Form is not valid')
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage("");
      setSuccessMessage("");
    }, 3000);

    return () => clearTimeout(timer);
  }, [errorMessage, successMessage]);
  return (
    <>
      <div className="footer-bg pt-16 lg:px-20 sm:px-10 px-5">
        <div className="grid lg:grid-cols-5 sm:grid-cols-2 grid-cols-1 lg:justify-items-start sm:justify-items-center gap-8">
          <div className="lg:col-span-2 col-span-1 pr-5">
            <a href="/">
              <img src="images/logo.png" alt="" />
            </a>
            <p className="mt-3 font-raleway md:text-2xl text-xl text-white">
              We work with a passion of taking challenges and creating new ones
              in tech sector.
            </p>
          </div>
          {/* Mobile View Start */}
          <div className="sm:hidden">
            <div className="flex items-center gap-3">
              <Icons.PhoneIconMob />
              <a
                href="tel:+971502464747"
                className="cursor-pointer font-raleway text-xl text-white"
              >
                +971 50 246 4747
              </a>
            </div>
            <div className="flex items-center gap-3 mt-2">
              <Icons.EmailIconMob />
              <a
                href="mailto:info@datacrypt.ae"
                className="cursor-pointer font-raleway text-xl text-white"
              >
                info@datacrypt.ae
              </a>
            </div>
          </div>
          {/* Mobile View Ends */}

          <div className="lg:col-span-2 col-span-1">
            <h2 className="font-raleway xl:text-3xl text-2xl font-semibold text-white sm:block hidden">
              Newsletter
            </h2>
            <h2 className="font-raleway xl:text-3xl text-2xl font-semibold text-white  sm:hidden block">
              Newsletter
            </h2>
            <p className="mt-5 font-raleway text-xl text-white sm:block hidden">
              Signup for our latest news & articles. We won’t give you spam
              mails.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="flex sm:mt-5 mt-2 bg-white w-full h-14 rounded-md">
                <input
                  type="email"
                  name="user_email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter Email Address"
                  className="w-full h-full px-2 font-raleway text-base text-[#3b3b3b] placeholder:text-[#3b3b3b] focus:border-0 focus:ring-0 focus:outline-none rounded-tl-md rounded-bl-md"
                />
                <button
                  type="submit"
                  className="px-4 bg-[#1B6B59] h-full flex items-center justify-center rounded-tr-md rounded-br-md"
                >
                  <Icons.ShareIcon />
                </button>
              </div>
            </form>
            <div className="h-12 mt-3">
              {errorMessage && <p className="text-red-500">{errorMessage}</p>}
              {successMessage && (
                <p className="text-green-500">{successMessage}</p>
              )}
            </div>
          </div>
          <div className="col-span-1 sm:block hidden w-full">
            <h2 className="font-raleway xl:text-3xl text-2xl font-semibold text-white">
              Contact Us
            </h2>
            <p className="mt-5">
              <a
                href="tel:+971502464747"
                className="cursor-pointer font-raleway text-xl text-white"
              >
                +971 50 246 4747
              </a>
            </p>
            <p className="mt-5">
              <a
                href="mailto:info@datacrypt.ae"
                className="cursor-pointer font-raleway text-xl text-white"
              >
                info@datacrypt.ae
              </a>
            </p>
            <p className="mt-5 font-raleway text-xl text-white">Dubai.U.A.E.</p>
          </div>
        </div>
        <div className="bg-gray-300 mt-14 w-full h-[1px]"></div>
        <div className="flex lg:flex-row flex-col justify-between items-center w-full py-5">
          <h3 className="font-raleway xl:text-xl sm:text-lg text-base text-white lg:text-start text-center">
            Copyright ©2023{" "}
            <a href="/">
              <span className="text-[#0FF]">DATACRYPT </span>
            </a>
            All rights reserved.
          </h3>
          <div className="flex items-center gap-10 lg:mt-0 mt-4">
            <Link to="/terms-and-conditions">
              <p className="font-raleway xl:text-xl sm:text-lg text-base text-white lg:text-start text-center">
                Terms & Conditions
              </p>
            </Link>
            <Link to="/privacy-policy">
              <p className="font-raleway xl:text-xl sm:text-lg text-base text-white lg:text-start text-center">
                Privacy Policy
              </p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsFooter;
