import { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="transformation-banner bg-center">
        <div className="w-full 2xl:container mx-auto lg:px-16 md:px-8 px-5 flex items-center h-full">
          <h1 className="font-montserrat lg:text-6xl md:text-5xl text-4xl font-semibold text-white">
            Terms & <br /> Conditions
          </h1>
        </div>
      </div>
      <div className="2xl:container mx-auto lg:px-16 md:px-8 px-5 py-16 xl:w-[85%] flex flex-col items-center">
        <h2 className="font-raleway lg:text-4xl md:text-3xl text-2xl font-semibold text-white mt-5 text-center">
          Terms <span className="text-primary">&</span> Conditions
        </h2>
        <div className="w-full">
          <p className="mt-5 text-white font-raleway md:text-xl text-lg">
            By accessing and using our website, you agree to abide by these
            terms and conditions. Our website offers services related to Digital
            Transformation, Cloud Application Migration, and other digital
            solutions. Users are expected to provide accurate information and
            refrain from engaging in unlawful activities while using our
            services. All content, logos, and materials displayed on the website
            are our intellectual property and should not be used without
            explicit permission. We are not liable for any damages or losses
            resulting from the use or inability to use our services. We reserve
            the right to terminate services or access to the website in case of
            any violations.
            <br />
            <br />
            <strong className="text-primary">
              1. Service Description:
            </strong>{" "}
            Our services are designed to assist businesses in implementing
            digital transformation strategies, migrating applications to cloud
            platforms, and providing comprehensive digital solutions. <br />{" "}
            <br />
            <strong className="text-primary">2. User Conduct:</strong> You must
            not use this website in any way that causes, or may cause, damage to
            the website or impairment of the availability or accessibility of
            the website. You must not use this website in any way that is
            unlawful, illegal, fraudulent, or harmful. <br /> <br />
            <strong className="text-primary">
              3. Intellectual Property:
            </strong>{" "}
            All content, trademarks, designs, logos, and graphics used on our
            website are our intellectual property and may not be reproduced or
            used without permission. <br /> <br />
            <strong className="text-primary">
              4. Limitation of Liability:
            </strong>{" "}
            Our website and services are provided on an "as is" basis. We shall
            not be liable for any direct, indirect, incidental, consequential,
            or punitive damages arising out of your access to or use of our
            services. <br /> <br />
            <strong className="text-primary">5. Termination:</strong> We reserve
            the right to terminate access to our services for any reason without
            notice at any time.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
