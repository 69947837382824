import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import TransformationPage from "./pages/TransformationPage";
import BrandingPage from "./pages/BrandingPage";
import SecurityPage from "./pages/SecurityPage";
import DataAnalyticsPage from "./pages/DataAnalyticsPage";
import DevelopmentPage from "./pages/DevelopmentPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route
          path="/digital-transformation"
          element={<TransformationPage />}
        ></Route>
        <Route path="/web-development" element={<DevelopmentPage />}></Route>
        <Route path="/data-analytics" element={<DataAnalyticsPage />}></Route>
        <Route path="/digital-branding" element={<BrandingPage />}></Route>
        <Route path="/cyber-security" element={<SecurityPage />}></Route>
        <Route path="/terms-and-conditions" element={<TermsPage />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPage />}></Route>
      </Routes>
    </>
  );
}

export default App;
