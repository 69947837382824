import * as Icons from "../common/Icons";

const DataObjectives = () => {
  return (
    <>
      <div className="grid md:grid-cols-2 grid-cols-1 xl:gap-10 pt-10 pb-20 2xl:container mx-auto lg:px-16 md:px-8 px-5">
        <div className="h-full flex items-center">
          <img src="images/data-analytics/img-1.png" alt="" />
        </div>
        <div className="md:px-10 px-5 md:mt-0 mt-10">
          <h3 className="text-primary font-raleway font-semibold lg:text-4xl text-3xl">
            Objectives:
          </h3>
          <div className="lg:mt-10 mt-6 flex items-center gap-5">
            <div className="w-16">
              <div className="w-14 h-14 rounded-md bg-[#6928DA] flex items-center justify-center text-white fill-current">
                <Icons.RightArrowIcons />
              </div>
            </div>
            <p className="text-white font-raleway lg:text-xl text-lg font-semibold">
              Data Quality Enhancement.
            </p>
          </div>
          <div className="mt-5 flex items-center gap-5">
            <div className="w-16">
              <div className="w-14 h-14 rounded-md bg-[#6928DA] flex items-center justify-center text-white fill-current">
                <Icons.RightArrowIcons />
              </div>
            </div>
            <p className="text-white font-raleway lg:text-xl text-lg font-semibold">
              Compliance & Security.
            </p>
          </div>
          <div className="mt-5 flex items-center gap-5">
            <div className="xl:w-16 w-[4.6rem]">
              <div className="w-14 h-14 rounded-md bg-[#6928DA] flex items-center justify-center text-white fill-current">
                <Icons.RightArrowIcons />
              </div>
            </div>
            <p className="text-white font-raleway lg:text-xl text-lg font-semibold">
              Business Performance Optimization.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataObjectives;
