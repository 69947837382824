import { useEffect } from "react";
import Testimonials from "../Home/Testimonials";
import * as Icons from "../common/Icons";
import MarketingObjectives from "./DataObjectives";
import MarketingStrategy from "./DataStrategy";

const DataAnalytics = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="data-analytics-banner bg-center">
        <div className="w-full 2xl:container mx-auto lg:px-16 md:px-8 px-5 flex items-center h-full">
          <h1 className="font-montserrat lg:text-6xl md:text-5xl text-4xl font-semibold text-white">
            Data <br /> Analytics
          </h1>
        </div>
      </div>
      <div className="2xl:container mx-auto lg:px-16 md:px-8 px-5 py-16 xl:w-[85%] flex flex-col items-center">
        <p className="text-dark-green text-center font-raleway text-xl font-medium tracking-[6.6px]">
          Case Studies
        </p>
        <h2 className="font-raleway lg:text-4xl md:text-3xl text-2xl font-semibold text-white mt-5 text-center">
          Data Analytics
        </h2>
        <div className="mt-12 w-full">
          <h3 className="text-primary font-raleway md:text-4xl text-3xl font-semibold">
            Overview
          </h3>
          <p className="mt-5 text-white font-raleway md:text-xl text-lg">
            An ecommerce company is intensifying its focus on data integrity and
            security. Through automated data validation and stringent privacy
            measures, they ensure compliance while fortifying cybersecurity.
            Leveraging advanced analytics for KPI-driven insights, the company
            aims to make data-centric decisions that elevate customer experience
            and drive business growth.
          </p>
        </div>
      </div>
      <MarketingObjectives />
      <MarketingStrategy />
      <div className="it-service my-10">
        <div className="2xl:container mx-auto px-10 h-full flex items-center justify-center">
          <h3 className="text-white font-raleway lg:text-4xl sm:text-2xl text-xl font-semibold text-center">
            We run all kinds of{" "}
            <span className="text-primary">IT services</span>{" "}
            that vows your success
          </h3>
        </div>
      </div>
      <Testimonials />
    </>
  );
};

export default DataAnalytics;
