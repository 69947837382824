import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const Testimonials = () => {
  return (
    <>
      <div className="pt-16 lg:block hidden">
        <p className="text-primary text-center font-raleway text-xl font-medium tracking-[6.6px]">
          Testimonials
        </p>
        <h2 className="font-raleway text-4xl font-semibold text-white mt-5 text-center">
          Reviews from our awesome clients
        </h2>
        <div className="overflow-x-hidden mt-14 mb-32 px-10">
          <Swiper
            spaceBetween={50}
            slidesPerView={2}
            onSlideChange={() => console.log("slide change")}
          >
            <SwiperSlide>
              <div className="testimonials-main">
                <div className="w-full">
                  <img
                    src="svgs/review-icons.svg"
                    alt=""
                    className="xl:w-auto w-44"
                  />
                </div>
                <p className="testimonials-text">
                  "Incredibly impressed by the exemplary showcase of
                  cutting-edge innovation and profound technical expertise that
                  was vividly displayed, setting a remarkable standard within
                  the industry and leaving a lasting impact on our perspective."
                </p>
                <div className="mt-5 w-full">
                  <h5 className="testimonials-author">By Aarav Patel</h5>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonials-main">
                <div className="w-full">
                  <img src="svgs/review-icons.svg" alt="" />
                </div>
                <p className="testimonials-text">
                  "Their work demonstrates a remarkable commitment to excellence
                  and a clear client-centric focus, setting a noteworthy
                  standard within the industry, ensuring unparalleled quality
                  and customer dedication."
                </p>
                <div className="mt-5 w-full">
                  <h5 className="testimonials-author">By Adanna Nwosu</h5>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonials-main">
                <div className="w-full">
                  <img src="svgs/review-icons.svg" alt="" />
                </div>
                <p className="testimonials-text">
                  "Their transformative journey reflects a remarkable
                  dedication, showcasing an unwavering commitment to change,
                  innovation, and progress, setting a high benchmark within the
                  industry's digital evolution."
                </p>
                <div className="mt-5 w-full">
                  <h5 className="testimonials-author">By Mei Ling</h5>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonials-main">
                <div className="w-full">
                <img src="svgs/review-icons.svg" alt="" />
                </div>
                <p className="testimonials-text">
                  "Evidencing an exemplary leadership role in innovation, their
                  delivery of top-notch tech solutions sets a precedent, paving
                  the way for cutting-edge advancements and industry standards,
                  setting a high bar for innovation and excellence."
                </p>
                <div className="mt-5 w-full">
                    <h5 className="testimonials-author">By Carlos Fernandez</h5>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
