import DetailsFooter from "../components/DetailsFooter";
import DetailsHeader from "../components/DetailsHeader";
import PrivacyPolicy from "../components/PrivacyPolicy";

const PrivacyPage = () => {
  return (
    <>
      <DetailsHeader />
      <PrivacyPolicy/>
      <DetailsFooter />
    </>
  );
};

export default PrivacyPage;
