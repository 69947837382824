import { Link } from "react-router-dom";
import * as Icons from "../common/Icons";

const CaseStudio = () => {
  return (
    <div className="case-studies-bg">
      <div id="case-studies">
        <div className="2xl:container mx-auto lg:px-16 md:px-8 px-5 py-16 xl:w-[85%] flex flex-col items-center">
          <p className="text-primary text-center font-raleway text-xl font-medium tracking-[6.6px]">
            Case Studies
          </p>
          <h2 className="font-raleway lg:text-4xl md:text-3xl text-2xl font-semibold text-white mt-5 text-center">
            Enhancing experience with enhanced technology
          </h2>
          <div className="mt-12 grid lg:grid-cols-3 grid-cols-2 lg:gap-10 gap-5">
            <div className="col-span-2 relative">
              <img src="images/studio/img-2.png" alt="" />
              <div className="absolute sm:bottom-7 bottom-4 sm:left-7 left-4">
                <h3 className="sm:text-2xl text-lg font-semibold text-white font-raleway mt-2">
                  Data <br />
                  Analytics
                </h3>
                <Link to="/data-analytics">
                  <button className="mt-3 flex items-center gap-3 sm:px-3 px-2 sm:py-3 py-1 bg-white font-raleway sm:text-lg text-sm font-semibold rounded-lg">
                    <div className="sm:block hidden">
                      <Icons.RightArrowIcons />
                    </div>
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-span-1 relative">
              <img src="images/studio/img-3.png" alt="" />
              <div className="absolute sm:bottom-7 bottom-4 sm:left-7 left-4">
                <h3 className="sm:text-2xl text-lg font-semibold text-white font-raleway mt-2">
                  Web <br /> Development
                </h3>
                <Link to="/web-development">
                  <button className="mt-3 flex items-center gap-3 sm:px-3 px-2 sm:py-3 py-1 bg-white font-raleway sm:text-lg text-sm font-semibold rounded-lg">
                    <div className="sm:block hidden">
                      <Icons.RightArrowIcons />
                    </div>
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
            <div className=" col-span-1 relative">
              <img src="images/studio/img-4.png" alt="" />
              <div className="absolute sm:bottom-7 bottom-4 sm:left-7 left-4">
                <h3 className="sm:text-2xl text-lg font-semibold text-white font-raleway mt-2">
                  Digital <br /> Transformation
                </h3>
                <Link to="/digital-transformation">
                  <button className="mt-3 flex items-center gap-3 sm:px-3 px-2 sm:py-3 py-1 bg-white font-raleway sm:text-lg text-sm font-semibold rounded-lg">
                    <div className="sm:block hidden">
                      <Icons.RightArrowIcons />
                    </div>
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-span-1 relative">
              <img src="images/studio/img-5.png" alt="" />
              <div className="absolute sm:bottom-7 bottom-4 sm:left-7 left-4">
                <h3 className="sm:text-2xl text-lg font-semibold text-white font-raleway mt-2">
                  Digital <br /> Branding
                </h3>
                <Link to="/digital-branding">
                  <button className="mt-3 flex items-center gap-3 sm:px-3 px-2 sm:py-3 py-1 bg-white font-raleway sm:text-lg text-sm font-semibold rounded-lg">
                    <div className="sm:block hidden">
                      <Icons.RightArrowIcons />
                    </div>
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-span-1 relative">
              <img src="images/studio/img-6.png" alt="" />
              <div className="absolute sm:bottom-7 bottom-4 sm:left-7 left-4">
                <h3 className="sm:text-2xl text-lg font-semibold text-white font-raleway mt-2">
                  Cyber <br /> Security
                </h3>
                <Link to="/cyber-security">
                  <button className="mt-3 flex items-center gap-3 sm:px-3 px-2 sm:py-3 py-1 bg-white font-raleway sm:text-lg text-sm font-semibold rounded-lg">
                    <div className="sm:block hidden">
                      <Icons.RightArrowIcons />
                    </div>
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudio;
