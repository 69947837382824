import * as Icons from "../common/Icons";
import BusinessGrowth from "./BusinessGrowth";
import CaseStudio from "./CaseStudio";
import Contact from "./Contact";
import DiscoverCompany from "./DiscoverCompany";
import OurServices from "./OurServices";
import Testimonials from "./Testimonials";

const Home = () => {
  return (
    <>
      <div id="home" className="banner-bg sm:bg-center flex items-center">
        <div className="w-full 2xl:container mx-auto lg:px-16 md:px-8 px-5">
          <h1 className="font-montserrat sm:text-6xl text-5xl font-medium text-white">
            The New Standard <br className="sm:block hidden" />
            in <span className="text-primary">Data Analysis</span>
          </h1>
          <p className="font-montserrat text-white sm:text-3xl text-xl font-normal mt-6">
            Business Transformation, Data Analytics{" "}
            <br className="sm:block hidden" />
            and Robotic Process Automation
          </p>
          <a href="#about-us">
            <button className="px-7 py-4 bg-transparent border border-primary rounded-lg text-primary hover:bg-primary hover:text-white fill-current flex items-center gap-4 font-raleway text-2xl font-semibold mt-6">
              <Icons.RightArrowIcons />
              Learn More
            </button>
          </a>
        </div>
      </div>
      <BusinessGrowth />
      <DiscoverCompany />
      <OurServices />
      <CaseStudio />
      <Testimonials />
      <Contact />
    </>
  );
};

export default Home;
