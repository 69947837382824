import * as Icons from "../common/Icons";

const OurServices = () => {
  return (
    <div className="services-bg">
      <div
        id="services"
        className="2xl:container mx-auto  lg:px-16 md:px-8 px-5 py-20 xl:w-[85%]"
      >
        <p className="text-primary text-center font-raleway md:text-xl text-base font-medium tracking-[6.6px]">
          Our Services
        </p>
        <h2 className="font-raleway sm:text-4xl text-2xl font-semibold text-white mt-5 text-center">
          Stay Up, Stay Running, Stay Protected
        </h2>
        <div className="mt-20 grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:gap-8 gap-5">
          {/* Card 1 */}
          <div className="services-card border border-primary rounded-[30px] pb-10 relative">
            <img src="/svgs/services/img-1.svg" alt="" className="mx-auto" />
            <div className="lg:px-5 px-3">
              <h3 className="company-card-head">Digital Transformation</h3>
              <p className="company-card-desc">
                Harness the power of Strategic Cloud Services, IoT and Machine
                Learning.
              </p>
            </div>
          </div>
          {/* Card 2 */}
          <div className="services-card border border-primary rounded-[30px] pb-10 relative">
            <img src="/svgs/services/img-2.svg" alt="" className="mx-auto" />
            <div className="lg:px-5 px-3">
              <h3 className="company-card-head">Cloud Application Migration</h3>
              <p className="company-card-desc">
                Our relocation methods are tried and tested, as well as quick
                and profitable.
              </p>
            </div>
          </div>
          {/* Card 3 */}
          <div className="services-card border border-primary rounded-[30px] pb-10 relative">
            <img src="/svgs/services/img-3.svg" alt="" className="mx-auto" />
            <div className="lg:px-5 px-3">
              <h3 className="company-card-head">
                Apps Modernization & Migration
              </h3>
              <p className="company-card-desc">
                Upgrade outdated applications to meet the demands of your
                business today and in the future.
              </p>
            </div>
          </div>
          {/* Card 4 */}
          <div className="services-card border border-primary rounded-[30px] pb-10 relative">
            <img src="/svgs/services/img-4.svg" alt="" className="mx-auto" />
            <div className="lg:px-5 px-3">
              <h3 className="company-card-head">Data And Intelligence</h3>
              <p className="company-card-desc">
                Changing your mind about what your data can do.
              </p>
            </div>
          </div>
          {/* Card 5 */}
          <div className="services-card border border-primary rounded-[30px] pb-10 relative">
            <img src="/svgs/services/img-5.svg" alt="" className="mx-auto" />
            <div className="lg:px-5 px-3">
              <h3 className="company-card-head">Cloud Security</h3>
              <p className="company-card-desc">
                Benefit from first-class lines of defense by implementing
                future-proof cloud-based security.
              </p>
            </div>
          </div>
          {/* Card 6 */}
          <div className="services-card border border-primary rounded-[30px] pb-10 relative">
            <img src="/svgs/services/img-6.svg" alt="" className="mx-auto" />
            <div className="lg:px-5 px-3">
              <h3 className="company-card-head">Cloud Migration</h3>
              <p className="company-card-desc">
                We'll assist you in determining and implementing the optimal
                relocation strategy for your company.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="it-service">
        <div className="2xl:container mx-auto lg:px-16 md:px-8 px-5 xl:w-[85%] h-full flex md:flex-row flex-col items-center md:justify-between justify-center gap-8">
          <h3 className="text-white font-raleway lg:text-4xl sm:text-2xl text-xl font-semibold sm:text-start text-center">
            We run all kinds of{" "}
            <span className="text-primary">IT services</span>{" "}
            <br className="xl:block hidden" />
            that vows your success
          </h3>
          <div className="flex gap-5">
            <a href="#contact">
              <div className="talk-btn flex items-center justify-center gap-3 sm:w-44 w-36 h-14 rounded-md">
                <div className="sm:block hidden">
                  <Icons.MessageIcon />
                </div>
                <p className="font-raleway text-white font-semibold lg:text-xl text-lg">
                  Let's Talk
                </p>
              </div>
            </a>
            <a href="#contact">
              <div className="flex items-center justify-center gap-3 sm:w-44 w-36 h-14 border border-white rounded-md">
                <div className="sm:block hidden">
                  <Icons.GetInfoIcon />
                </div>
                <p className="font-raleway text-white font-semibold lg:text-xl text-lg">
                  Get Info
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
