import { useState } from "react";
import * as Icons from "../common/Icons";

const DevelopmentStrategy = () => {
  const [showFrontend, setShowFrontend] = useState<boolean>(true);
  const [showBackend, setShowBackend] = useState<boolean>(false);
  const [showFullstack, setShowFullstack] = useState<boolean>(false);
  const [showDatabase, setShowDatabase] = useState<boolean>(false);
  const [showFrameworks, setShowFrameworks] = useState<boolean>(false);
  const [showCompatibility, setShowCompatibility] = useState<boolean>(false);

  const handleFrontend = () => {
    setShowFrontend(!showFrontend);
    setShowBackend(false);
    setShowFullstack(false);
    setShowDatabase(false);
    setShowFrameworks(false);
    setShowCompatibility(false);
  };

  const handleBackend = () => {
    setShowBackend(!showBackend);
    setShowFrontend(false);
    setShowFullstack(false);
    setShowDatabase(false);
    setShowFrameworks(false);
    setShowCompatibility(false);
  };

  const handleFullstack = () => {
    setShowFullstack(!showFullstack);
    setShowFrontend(false);
    setShowBackend(false);
    setShowDatabase(false);
    setShowFrameworks(false);
    setShowCompatibility(false);
  };

  const handleDatabase = () => {
    setShowDatabase(!showDatabase);
    setShowFrontend(false);
    setShowBackend(false);
    setShowFullstack(false);
    setShowFrameworks(false);
    setShowCompatibility(false);
  };

  const handleFrameworks = () => {
    setShowDatabase(false);
    setShowFrontend(false);
    setShowBackend(false);
    setShowFullstack(false);
    setShowFrameworks(!showFrameworks);
    setShowCompatibility(false);
  };

  const handleCompatibility = () => {
    setShowDatabase(false);
    setShowFrontend(false);
    setShowBackend(false);
    setShowFullstack(false);
    setShowFrameworks(false);
    setShowCompatibility(!showCompatibility);
  };

  return (
    <>
      <div className="py-14 2xl:container mx-auto sm:px-16 px-5 xl:w-[85%]">
        <h3 className="text-primary font-raleway lg:text-4xl text-3xl font-semibold text-center">
          Strategies Implemented:
        </h3>
        {/* Desktop view */}
        <div className="md:block hidden">
          <div className="mt-8 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Front-end Development:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              This involves the visual and interactive parts of a website that
              users see and interact with directly. It includes HTML, CSS, and
              JavaScript to create the layout, design, and functionality of the
              site in web browsers.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Back-end Development:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              This involves the server-side of web development. It deals with
              the logic, database interactions, user authentication, and server
              configuration. Languages like Python, Ruby, PHP, and frameworks
              like Node.js are commonly used for back-end development.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Full-stack Development:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Developers who work on both the front-end and back-end of a
              website or application are called full-stack developers.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Databases:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Storing and managing data is a crucial aspect of web development.
              Technologies like MySQL, MongoDB, PostgreSQL, and others are used
              for this purpose.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Frameworks and Libraries:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Developers often use various frameworks and libraries to
              streamline the development process. For example, front-end
              frameworks like React, Angular, or Vue.js and back-end frameworks
              like Express for Node.js, Django for Python, or Ruby on Rails for
              Ruby.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Responsive Design and Cross-Browser Compatibility:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Websites need to be accessible and functional across various
              devices and browsers.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
        </div>

        {/* Mobile view */}
        <div className="md:hidden block">
          <div
            onClick={handleFrontend}
            className={`mt-8 w-full ${
              showFrontend ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Front-end Development:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showFrontend ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              This involves the visual and interactive parts of a website that
              users see and interact with directly. It includes HTML, CSS, and
              JavaScript to create the layout, design, and functionality of the
              site in web browsers.
            </p>
            <div
              className={`absolute ${
                showFrontend ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleBackend}
            className={`mt-8 w-full ${
              showBackend ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Back-end Development:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showBackend ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              This involves the server-side of web development. It deals with
              the logic, database interactions, user authentication, and server
              configuration. Languages like Python, Ruby, PHP, and frameworks
              like Node.js are commonly used for back-end development.
            </p>
            <div
              className={`absolute ${
                showBackend ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleFullstack}
            className={`mt-8 w-full ${
              showFullstack ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Full-stack Development:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showFullstack ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Developers who work on both the front-end and back-end of a
              website or application are called full-stack developers.
            </p>
            <div
              className={`absolute ${
                showFullstack ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleDatabase}
            className={`mt-8 w-full ${
              showDatabase ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Databases:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showDatabase ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Storing and managing data is a crucial aspect of web development.
              Technologies like MySQL, MongoDB, PostgreSQL, and others are used
              for this purpose.
            </p>
            <div
              className={`absolute ${
                showDatabase ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleFrameworks}
            className={`mt-8 w-full ${
              showFrameworks ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Frameworks and Libraries:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showFrameworks ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Developers often use various frameworks and libraries to
              streamline the development process. For example, front-end
              frameworks like React, Angular, or Vue.js and back-end frameworks
              like Express for Node.js, Django for Python, or Ruby on Rails for
              Ruby.
            </p>
            <div
              className={`absolute ${
                showFrameworks ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleCompatibility}
            className={`mt-8 w-full ${
              showCompatibility ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Responsive Design and Cross-Browser Compatibility:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showCompatibility ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Websites need to be accessible and functional across various
              devices and browsers.
            </p>
            <div
              className={`absolute ${
                showCompatibility ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
        </div>
        <h3 className="text-primary font-raleway text-4xl font-semibold text-center mt-20">
          Results:
        </h3>
        <div className="mt-8 flex sm:flex-row flex-col items-center justify-center md:gap-20 sm:gap-10 gap-5">
          <div className="result-box h-60 sm:w-80 w-full flex flex-col items-center justify-center px-5">
            <h3 className="text-primary text-4xl font-semibold font-raleway">
              35%
            </h3>
            <p className="font-raleway text-2xl font-semibold text-white mt-3 text-center">
              Increase in user engagement
            </p>
          </div>
          <div className="result-box h-60 sm:w-80 w-full flex flex-col items-center justify-center px-5">
            <h3 className="text-primary text-4xl font-semibold font-raleway">
              40%
            </h3>
            <p className="font-raleway text-2xl font-semibold text-white mt-3 text-center">
              Reduction in security incidents
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DevelopmentStrategy;
