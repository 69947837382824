import { useState } from "react";
import * as Icons from "../common/Icons";

const BrandingStrategy = () => {
  const [showAssets, setShowAssets] = useState<boolean>(true);
  const [showCreation, setShowCreation] = useState<boolean>(false);
  const [showCommunity, setShowCommunity] = useState<boolean>(false);
  const [showSupport, setShowSupport] = useState<boolean>(false);

  const handleAssets = () => {
    setShowAssets(!showAssets);
    setShowCreation(false);
    setShowCommunity(false);
    setShowSupport(false);
  };

  const handleCreation = () => {
    setShowCreation(!showCreation);
    setShowAssets(false);
    setShowCommunity(false);
    setShowSupport(false);
  };

  const handleCommunity = () => {
    setShowCommunity(!showCommunity);
    setShowAssets(false);
    setShowCreation(false);
    setShowSupport(false);
  };

  const handleSupport = () => {
    setShowSupport(!showSupport);
    setShowAssets(false);
    setShowCreation(false);
    setShowCommunity(false);
  };

  return (
    <>
      <div className="py-14 2xl:container mx-auto sm:px-16 px-5 xl:w-[85%]">
        <h3 className="text-primary font-raleway lg:text-4xl text-3xl font-semibold text-center">
          Strategies Implemented:
        </h3>
        {/* Desktop view */}
        <div className="md:block hidden">
          <div className="mt-8 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Logo and Visual Assets:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Invested in a professional logo that encapsulated
              their values and ethos. They developed a color palette and design
              elements that reflected nature and sustainability. Consistency in
              visual branding was maintained across all digital channels.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Compelling Content Creation:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Created a blog featuring articles on natural
              skincare, environmental impact, and wellness. They also crafted
              engaging social media content, including educational posts,
              user-generated content, and behind-the-scenes stories of sourcing
              natural ingredients and product creation.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Community Engagement
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              <li>
                <span className="text-primary">
                  User-Generated Content Campaigns:
                </span>{" "}
                They initiated campaigns encouraging customers to share their
                experiences regarding products, showcasing real
                results and fostering a sense of community.
              </li>
              <li>
                <span className="text-primary">
                  Engagement through Sustainability Initiatives:
                </span>{" "}
                Demonstrating their commitment to sustainability, the brand
                engaged in eco-friendly initiatives, sharing progress, and
                involving the audience in environmental causes.
              </li>
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Customer Experience and Support:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              Emphasizing responsive and informative customer service, resolving
              queries and providing guidance on product usage, ingredients, and
              sustainability practices. Creating a positive customer experience
              was a crucial aspect of their branding efforts.
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
        </div>

        {/* Mobile view */}
        <div className="md:hidden block">
          <div
            onClick={handleAssets}
            className={`mt-8 w-full ${
              showAssets ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Logo and Visual Assets:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showAssets ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Nature's Essence invested in a professional logo that encapsulated
              their values and ethos. They developed a color palette and design
              elements that reflected nature and sustainability. Consistency in
              visual branding was maintained across all digital channels.
            </p>
            <div
              className={`absolute ${
                showAssets ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleCreation}
            className={`mt-8 w-full ${
              showCreation ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Compelling Content Creation:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showCreation ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Nature's Essence created a blog featuring articles on natural
              skincare, environmental impact, and wellness. They also crafted
              engaging social media content, including educational posts,
              user-generated content, and behind-the-scenes stories of sourcing
              natural ingredients and product creation.
            </p>
            <div
              className={`absolute ${
                showCreation ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleCommunity}
            className={`mt-8 w-full ${
              showCommunity ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Community Engagement
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showCommunity ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              <li>
                <span className="text-primary">
                  User-Generated Content Campaigns:
                </span>{" "}
                They initiated campaigns encouraging customers to share their
                experiences with Nature's Essence products, showcasing real
                results and fostering a sense of community.
              </li>
              <li>
                <span className="text-primary">
                  Engagement through Sustainability Initiatives:
                </span>{" "}
                Demonstrating their commitment to sustainability, the brand
                engaged in eco-friendly initiatives, sharing progress, and
                involving the audience in environmental causes.
              </li>
            </p>
            <div
              className={`absolute ${
                showCommunity ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleSupport}
            className={`mt-8 w-full ${
              showSupport ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Customer Experience and Support:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showSupport ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              Emphasizing responsive and informative customer service, resolving
              queries and providing guidance on product usage, ingredients, and
              sustainability practices. Creating a positive customer experience
              was a crucial aspect of their branding efforts.
            </p>
            <div
              className={`absolute ${
                showSupport ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
        </div>
        <h3 className="text-primary font-raleway text-4xl font-semibold text-center mt-20">
          Results:
        </h3>
        <div className="mt-8 flex sm:flex-row flex-col items-center justify-center md:gap-20 sm:gap-10 gap-5">
          <div className="result-box h-60 sm:w-80 w-full flex flex-col items-center justify-center px-5">
            <h3 className="text-primary text-4xl font-semibold font-raleway">
              20%
            </h3>
            <p className="font-raleway text-2xl font-semibold text-white mt-3 text-center">
              Increase in brand differentiation
            </p>
          </div>
          <div className="result-box h-60 sm:w-80 w-full flex flex-col items-center justify-center px-5">
            <h3 className="text-primary text-4xl font-semibold font-raleway">
              35%
            </h3>
            <p className="font-raleway text-2xl font-semibold text-white mt-3 text-center">
              Growth in online market engagement
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandingStrategy;
