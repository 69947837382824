import DetailsFooter from "../components/DetailsFooter";
import DetailsHeader from "../components/DetailsHeader";
import DigitalTransformation from "../components/DigitalTransformation";

const TransformationPage = () => {
  return (
    <>
      <DetailsHeader />
      <DigitalTransformation />
      <DetailsFooter />
    </>
  );
};

export default TransformationPage;
