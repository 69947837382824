import { useEffect } from "react";
import * as Icons from "../common/Icons";
import UpperNav from "./UpperNav";
import { Link } from "react-router-dom";

const DetailsHeader = () => {
  useEffect(() => {
    let prevScroll = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const navbar = document.getElementById("navbar");

      if (!navbar) return;

      if (document.documentElement.scrollTop < 10) {
        navbar.style.backgroundColor = "transparent";
      } else {
        navbar.style.backgroundColor = "#171E26";
      }

      if (prevScroll > currentScrollPos) {
        navbar.style.top = "0";
      } else {
        navbar.style.top = "-150px";
        navbar.style.transition = "0.5s";
      }

      prevScroll = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div id="navbar" className="sticky top-0 w-full z-40 border-b border-primary">
      <UpperNav />
      <div className="w-full sm:h-0 h-20 header-bg">
        {/* Mobile View */}
        <div className="md:hidden flex items-center justify-between sm:px-10 px-5 h-full">
          <Link to="/">
            <div className="text-white fill-current">
              <Icons.BackIcon />
            </div>
          </Link>
          <div className="md:hidden flex justify-center w-full">
            <Link to="/">
              <img src="/images/logo.png" alt="" className="w-40" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsHeader;
