import { useState, ChangeEvent, FormEvent, useEffect } from "react";
import emailjs from "emailjs-com";

interface ContactProps {}

const Contact: React.FC<ContactProps> = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [department, setDepartment] = useState<string>('select-department');
  const [description, setDescription] = useState<string>('');
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});
  const [successMessage, setSuccessMessage] = useState<string>('');

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleDepartmentChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDepartment(e.target.value);
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const validateEmailFormat = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = (): boolean => {
    const errors: Record<string, string> = {};

    if (name.trim() === '') {
      errors.name = 'Name is required.';
    }

    if (email.trim() === '') {
      errors.email = 'Email is required.';
    } else if (!validateEmailFormat(email)) {
      errors.email = 'Please enter a valid email address.';
    }

    if (department === 'select-department') {
      errors.department = 'Please select a department.';
    }

    if (description.trim() === '') {
      errors.description = 'Description is required.';
    }

    console.log('Errors:', errors); // Log the errors object

    setErrorMessages(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form && validateForm()) {
      emailjs
        .sendForm('service_akyfdkn', 'template_abap6eb', form, 'SQPDB41KRaajJ43Zt')
        .then((response) => {
          setName('');
          setEmail('');
          setDepartment('select-department');
          setDescription('');
          setSuccessMessage('Form submitted successfully!');
        })
        .catch((error) => {
          setSuccessMessage('Error sending email. Please try again.');
        });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessages({});
      setSuccessMessage('');
    }, 3000);

    return () => clearTimeout(timer);
  }, [errorMessages, successMessage]);

  return (
    <div className="contact-bg">
      <div
        id="contact"
        className="2xl:container mx-auto lg:px-16 md:px-8 px-5 pb-16 xl:w-[85%] grid md:grid-cols-2 grid-cols-1 xl:gap-28 lg:gap-16 gap-10"
      >
        <form onSubmit={handleSubmit}>
          <p className="text-primary font-raleway lg:text-xl text-base font-medium tracking-[6.6px]">
            Contact Us Now
          </p>
          <h2 className="font-raleway xl:text-4xl sm:text-3xl text-2xl font-semibold text-[#b1b1b1] mt-5">
            Are you ready for a better and more productive business?
          </h2>
          <div className="mt-10 grid grid-cols-2 gap-5">
            <div>
              <input
                type="text"
                name="user_name"
                value={name}
                onChange={handleNameChange}
                className="w-full bg-white h-12 px-4 font-raleway text-xl text-[#3b3b3b] placeholder:text-[#3b3b3b] focus:border-0 focus:ring-0 focus:outline-none rounded-md"
                placeholder="Name*"
              />
              {errorMessages.name && <p className="text-red-500 mt-2">{errorMessages.name}</p>}
            </div>
            <div>
              <input
                type="email"
                name="user_email"
                value={email}
                onChange={handleEmailChange}
                className="w-full bg-white h-12 px-4 font-raleway text-xl text-[#3b3b3b] placeholder:text-[#3b3b3b] focus:border-0 focus:ring-0 focus:outline-none rounded-md"
                placeholder="Email*"
              />
              {errorMessages.email && <p className="text-red-500 mt-2">{errorMessages.email}</p>}
            </div>
          </div>
          <select
            value={department}
            name="user_department"
            onChange={handleDepartmentChange}
            className="w-full bg-white h-12 px-4 font-raleway text-xl text-[#3b3b3b] placeholder:text-[#3b3b3b] focus:border-0 focus:ring-0 focus:outline-none mt-5 rounded-md"
            id="department"
          >
            <option value="select-department">
              Select Department to email*
            </option>
            <option value="finance">Finance</option>
            <option value="sales">Sales</option>
            <option value="management">Management</option>
          </select>
          {errorMessages.department && <p className="text-red-500 mt-2">{errorMessages.department}</p>}
          <textarea
            value={description}
            name="user_description"
            onChange={handleDescriptionChange}
            className="w-full bg-white p-4 font-raleway text-xl text-[#3b3b3b] placeholder:text-[#3b3b3b] focus:border-0 focus:ring-0 focus:outline-none mt-5 rounded-md"
            rows={7}
            placeholder="Please describe what you need.*"
          ></textarea>
          {errorMessages.description && <p className="text-red-500 mt-2">{errorMessages.description}</p>}
          {successMessage && (
            <p className="text-green-500">{successMessage}</p>
          )}
          <div className="h-6">
          
          </div>
          <button
            type="submit"
            className="mt-2 text-raleway text-xl font-semibold text-white px-8 py-3 bg-[#2C0080] rounded-md"
          >
            Get Consultation
          </button>
        </form>
        <div className="flex flex-col justify-center">
          <p className="text-white font-raleway lg:text-xl text-lg font-medium tracking-[6.6px] mb-5">
            Reach Out Now!
          </p>
          <a
            href="tel:+971502464747"
            className="text-primary font-raleway xl:text-5xl lg:text-4xl text-3xl font-extrabold"
          >
            (+971) 50 246 4747
          </a>
          <p className="font-raleway lg:text-2xl text-xl font-semibold text-[#b1b1b1] mt-5">
            Reach out to the world’s most reliable IT services.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
