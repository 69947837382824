import { useState } from "react";
import * as Icons from "../common/Icons";

const SecurityStrategy = () => {
  const [showResponse, setShowResponse] = useState<boolean>(true);
  const [showManagement, setShowManagement] = useState<boolean>(false);
  const [showSecurity, setShowSecurity] = useState<boolean>(false);

  const handleResponse = () => {
    setShowResponse(!showResponse);
    setShowManagement(false);
    setShowSecurity(false);
  };

  const handleManagement = () => {
    setShowManagement(!showManagement);
    setShowResponse(false);
    setShowSecurity(false);
  };

  const handleSecurity = () => {
    setShowSecurity(!showSecurity);
    setShowResponse(false);
    setShowManagement(false);
  };

  return (
    <>
      <div className="py-14 2xl:container mx-auto sm:px-16 px-5 xl:w-[85%]">
        <h3 className="text-primary font-raleway lg:text-4xl text-3xl font-semibold text-center">
          Strategies Implemented:
        </h3>
        {/* Desktop view */}
        <div className="md:block hidden">
          <div className="mt-8 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Incident Response:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              <li>
                <span className="text-primary">Objective:</span> Promptly and
                effectively respond to security incidents to minimize damage and
                restore systems.
              </li>
              <li>
                <span className="text-primary">Key Results:</span> Timely
                identification of the attack, containment, and swift resolution
                to reduce downtime and potential data loss.
              </li>
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Vulnerability Management:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              <li>
                <span className="text-primary">Objective:</span> Identify and
                patch vulnerabilities to prevent potential exploitation by
                attackers.
              </li>
              <li>
                <span className="text-primary">Key Results:</span> Regular
                vulnerability assessments, patch management, and proactive
                security measures to mitigate risks.
              </li>
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
          <div className="mt-5 w-full py-6 pl-16 pr-5 border border-primary rounded-3xl relative">
            <h3 className="text-primary font-raleway sm:text-3xl text-2xl font-semibold">
              Enhanced Security Protocols:
            </h3>
            <p className="font-raleway text-white sm:text-xl text-lg mt-3">
              <li>
                <span className="text-primary">Objective:</span> Strengthen
                security measures to fortify network, system, and data
                integrity.
              </li>
              <li>
                <span className="text-primary">Key Results:</span>{" "}
                Implementation of robust access controls, encryption, and
                multi-factor authentication to secure systems.
              </li>
            </p>
            <div className="absolute top-9 sm:-left-10 -left-3 sm:h-20 h-14 sm:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center">
              <Icons.StrategyIcon />
            </div>
          </div>
        </div>

        {/* Mobile view */}
        <div className="md:hidden block">
          <div
            onClick={handleResponse}
            className={`mt-8 w-full ${
              showResponse ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Incident Response:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showResponse ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              <li>
                <span className="text-primary">Objective:</span> Promptly and
                effectively respond to security incidents to minimize damage and
                restore systems.
              </li>
              <li>
                <span className="text-primary">Key Results:</span> Timely
                identification of the attack, containment, and swift resolution
                to reduce downtime and potential data loss.
              </li>
            </p>
            <div
              className={`absolute ${
                showResponse ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleManagement}
            className={`mt-8 w-full ${
              showManagement ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Vulnerability Management:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showManagement ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              <li>
                <span className="text-primary">Objective:</span> Identify and
                patch vulnerabilities to prevent potential exploitation by
                attackers.
              </li>
              <li>
                <span className="text-primary">Key Results:</span> Regular
                vulnerability assessments, patch management, and proactive
                security measures to mitigate risks.
              </li>
            </p>
            <div
              className={`absolute ${
                showManagement ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
          <div
            onClick={handleSecurity}
            className={`mt-8 w-full ${
              showSecurity ? "py-6" : "py-4"
            } pl-16 pr-5 border border-primary rounded-3xl relative transition-all ease-in-out duration-300`}
          >
            <h3 className="text-primary font-raleway sm:text-3xl text-xl sm:font-semibold font-bold">
              Enhanced Security Protocols:
            </h3>
            <p
              className={`font-raleway text-white sm:text-xl text-lg overflow-hidden transition-all ease-in-out duration-300 ${
                showSecurity ? "h-auto mt-3" : "h-0 mt-0"
              }`}
            >
              <li>
                <span className="text-primary">Objective:</span> Strengthen
                security measures to fortify network, system, and data
                integrity.
              </li>
              <li>
                <span className="text-primary">Key Results:</span>{" "}
                Implementation of robust access controls, encryption, and
                multi-factor authentication to secure systems.
              </li>
            </p>
            <div
              className={`absolute ${
                showSecurity ? "top-9" : "top-1"
              } md:-left-10 -left-3 md:h-20 h-14 md:w-20 w-14 rounded-full bg-black border border-primary flex items-center justify-center transition-all ease-in-out duration-300 p-[6px]`}
            >
              <Icons.StrategyIcon />
            </div>
          </div>
        </div>
        <h3 className="text-primary font-raleway text-4xl font-semibold text-center mt-20">
          Results:
        </h3>
        <div className="mt-8 flex sm:flex-row flex-col items-center justify-center md:gap-20 sm:gap-10 gap-5">
          <div className="result-box h-60 sm:w-80 w-full flex flex-col items-center justify-center px-5">
            <h3 className="text-primary text-4xl font-semibold font-raleway">
              25%
            </h3>
            <p className="font-raleway text-2xl font-semibold text-white mt-3 text-center">
              Reduction in security incidents
            </p>
          </div>
          <div className="result-box h-60 sm:w-80 w-full flex flex-col items-center justify-center px-5">
            <h3 className="text-primary text-4xl font-semibold font-raleway">
              30%
            </h3>
            <p className="font-raleway text-2xl font-semibold text-white mt-3 text-center">
              Increase in client confidence and trust
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurityStrategy;
