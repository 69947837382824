import DetailsFooter from "../components/DetailsFooter";
import DetailsHeader from "../components/DetailsHeader";
import DigitalBranding from "../components/DigitalBranding";

const BrandingPage = () => {
  return (
    <>
      <DetailsHeader />
      <DigitalBranding/>
      <DetailsFooter />
    </>
  );
};

export default BrandingPage;
