import DetailsFooter from "../components/DetailsFooter";
import DetailsHeader from "../components/DetailsHeader";
import TermsAndConditions from "../components/TermsAndConditions";

const TermsPage = () => {
  return (
    <>
      <DetailsHeader />
      <TermsAndConditions/>
      <DetailsFooter />
    </>
  );
};

export default TermsPage;
