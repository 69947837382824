import { useState, useEffect } from "react";
import * as Icons from "../common/Icons";
import UpperNav from "./UpperNav";

const Header = () => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  useEffect(() => {
    let prevScroll = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const navbar = document.getElementById("navbar");

      if (!navbar) return;

      if (document.documentElement.scrollTop < 10) {
        navbar.style.backgroundColor = "transparent";
      } else {
        navbar.style.backgroundColor = "#171E26";
      }

      if (prevScroll > currentScrollPos) {
        navbar.style.top = "0";
      } else {
        navbar.style.top = "-150px";
        navbar.style.transition = "0.5s";
      }

      prevScroll = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header id="navbar" className="w-full sticky top-0 z-40">
      <UpperNav />
      <div className="w-full sm:h-14 h-20 header-bg">
        {/* Desktop View */}
        <div className="md:flex hidden items-center justify-center 2xl:container mx-auto lg:px-16 px-5 h-full">
          <nav className="flex items-center h-full lg:gap-8 gap-5">
            <a href="#home" className="nav-items">
              Home
            </a>
            <a href="#about-us" className="nav-items">
              About us
            </a>
            <a href="#services" className="nav-items">
              Services
            </a>
            <a href="#case-studies" className="nav-items">
              Case Studies
            </a>
            <a href="#contact" className="nav-items">
              Contact
            </a>
          </nav>
        </div>

        {/* Mobile View */}
        <div className="md:hidden flex items-center justify-between sm:px-10 px-5 h-full">
          <div className="md:hidden">
            <a href="#home">
              <img src="/images/logo.png" alt="" className="w-40" />
            </a>
          </div>
          <img
            onClick={() => setShowSidebar(true)}
            src="svgs/hamburger-menu.svg"
            alt=""
          />
          <div
            className={`fixed top-0 sm:w-1/2 w-3/5 ${
              showSidebar ? "right-0" : "-right-[100%]"
            } overflow-x-hidden transition-all ease-in-out duration-500 h-screen bg-white z-50 shadow-xl shadow-black`}
          >
            <div className="w-full h-full relative">
              <div
                onClick={() => setShowSidebar(false)}
                className="absolute top-5 right-5"
              >
                <Icons.CrossIcon />
              </div>
              <nav className="flex flex-col justify-center items-center h-full lg:gap-8 gap-5">
                <a
                  onClick={() => setShowSidebar(false)}
                  href="#home"
                  className="nav-items-mob"
                >
                  Home
                </a>
                <a
                  onClick={() => setShowSidebar(false)}
                  href="#about-us"
                  className="nav-items-mob"
                >
                  About us
                </a>
                <a
                  onClick={() => setShowSidebar(false)}
                  href="#services"
                  className="nav-items-mob"
                >
                  Services
                </a>
                <a
                  onClick={() => setShowSidebar(false)}
                  href="#case-studies"
                  className="nav-items-mob"
                >
                  Case Studies
                </a>
                <a
                  onClick={() => setShowSidebar(false)}
                  href="#contact"
                  className="nav-items-mob"
                >
                  Contact
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
