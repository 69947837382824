import React from "react";

const BusinessGrowth = () => {
  return (
    <>
      <div className="flex justify-center -mt-20 relative 2xl:container mx-auto lg:px-16 md:px-8 px-5">
        <img src="images/business-growth.png" alt="" className="md:block hidden" />
        <div className="md:absolute xl:top-6 lg:top-14 top-5 md:gap-8 grid md:grid-cols-2 grid-cols-1 md:justify-items-start justify-items-center sm:w-4/5 md:bg-transparent bg-primary sm:p-10 p-6 rounded-3xl">
          <h1 className="font-raleway xl:text-5xl lg:text-4xl sm:text-3xl text-2xl text-white xl:leading-[60px] md:text-start text-center">
            Let Your Data Take <br className="xl:block hidden" /> Your{" "}
            <span className="font-semibold">Business</span> to <br className="xl:block hidden"/>
            <span className="font-semibold">Higher Grounds</span>
          </h1>
          <p className="font-raleway xl:text-2xl lg:text-xl text-base font-normal md:text-start text-center md:mt-0 mt-5 text-white">
            Data Crypt assists businesses in digitally transforming themselves
            by utilizing the cloud and implementing an operational model that is
            aligned with your objectives, lowering risk and cost while
            providing a managed, secure, and adaptable outcome.
          </p>
        </div>
      </div>
    </>
  );
};

export default BusinessGrowth;
